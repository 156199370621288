import frontend from './skill/frontend';
import backend from './skill/backend';
import misc from './skill/misc';
import database from './skill/database';
import lang from './skill/lang';

const HARD = [frontend, backend, database, misc];
const SOFT = [lang];
const ALL = [frontend, backend, database, misc, lang];
const SKILLS_RESUME = [
    {
        name: 'skills',
        text: 'Compétences principales',
        custom: 'title'
    },
    {
        name: 'skill-1',
        text: 'Javascript'
    },
    {
        name: 'skill-2',
        text: 'NodeJS'
    },
    {
        name: 'skill-3',
        text: 'VueJS'
    }
];

export { HARD, SOFT, SKILLS_RESUME, ALL };
