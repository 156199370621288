const backend = {
    title: 'Backend',
    skills: [
        {
            name: 'JavaScript',
            icon: 'js',
            level: 4
        },
        {
            name: 'NodeJs',
            icon: 'node',
            level: 3
        },
        {
            name: 'Koa',
            icon: 'koa',
            level: 4
        },
        {
            name: 'Express',
            icon: 'express',
            level: 4
        },
        {
            name: 'Hapi',
            icon: 'hapi',
            level: 2
        },
        {
            name: 'Shell',
            icon: 'shell',
            level: 2
        },
        {
            name: 'Sequelize',
            icon: 'sequelize',
            level: 3
        },
        {
            name: 'Mongoose',
            icon: 'mongoose',
            level: 3
        }
    ]
};

export default backend;
