import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import LevelIndicator from '../LevelIndicator';

export default function SkillItem(props) {
    const { skill } = props;
    return (
        <React.Fragment>
            <div className="row justify-center">
                <div className="row skill-item__element-wrapper full justify-center">
                    <div className="col skill-item__logo-container justify-center">
                        <div className={`skill-item__icon ${skill.icon}`} />
                    </div>
                    <div className="skill-item__text-container ml-s">
                        <p>{skill.name}</p>
                    </div>
                    <div className="col skill-item__level-container justify-center ml-xs">
                        <LevelIndicator level={skill.level} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

SkillItem.propTypes = {
    skill: PropTypes.object.isRequired
};
