import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import AppHeader from './components/Header';
import MainContainer from './components/MainContainer';
import './App.css';

function App() {
    return (
        <Router>
            <div className="col app">
                <AppHeader />
                <MainContainer />
            </div>
        </Router>
    );
}

export default App;
