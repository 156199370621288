import React from 'react';
import PropTypes from 'prop-types';

export default function InfoLineMail(props) {
    const { text } = props;
    function onClick() {
        window.location.href = `mailto:${text}`;
    }

    return (
        <span className="link" onClick={() => onClick()}>
            {text}
        </span>
    );
}

InfoLineMail.propTypes = {
    text: PropTypes.string.isRequired
};
