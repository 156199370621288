import React, { useEffect } from 'react';
import HOBBIES from '../../texts/hobbies';
import Hobby from '../../components/Hobby';

export default function Hobbies() {
    useEffect(() => {
        document.title = 'Morgan DENIS - loisirs';
    }, []);

    return (
        <React.Fragment>
            <div className="col pb-m">
                {HOBBIES.slice(1)
                    .filter((h) => !h.hidden)
                    .map((hobby, index) => (
                        <div key={index} className="row mt-m">
                            <Hobby hobby={hobby} />
                        </div>
                    ))}
            </div>
        </React.Fragment>
    );
}
