import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';

export default function InfoLineBirthDate(props) {
    const text = moment(props.text, 'DD/MM/YYYY').fromNow(true);
    return <span>Fier papa&nbsp;depuis&nbsp;{text}</span>;
}

InfoLineBirthDate.propTypes = {
    text: PropTypes.string.isRequired
};
