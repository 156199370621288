import React from 'react';
import PropTypes from 'prop-types';

export default function SportItem(props) {
    const { name, place, date, about, results } = props;
    return (
        <React.Fragment>
            <div className="col full-width">
                <div className="row mb-xs">
                    <h3>{name}</h3>
                </div>
                <div className="row mb-xs">{date}</div>
                <div className="row mb-xs">{about}</div>
                <div className="row mb-xs">{place}</div>
                {results !== '' ? (
                    <div className="row mb-m">Fini en : {results}</div>
                ) : (
                    <div className="row mb-m">A venir</div>
                )}
            </div>
        </React.Fragment>
    );
}

SportItem.propTypes = {
    name: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    results: PropTypes.string.isRequired
};
