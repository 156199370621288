const SPORTS = [
    {
        name: 'Course VERTIGO',
        place: 'Tour CB21 (la Défense)',
        date: 'Le 12 Mai 2022',
        about: '188 mètres, 42 étages, 977 marches',
        results: '8 minutes et 9 secondes'
    },
    {
        name: 'Trail des volcans',
        place: 'Orcines (col de Ceyssat) -> Saint-Ours',
        date: 'Le 4 Juin 2022',
        about: '32km, 1200m D+',
        results: '5 heures 31 minutes et 37 secondes'
    },
    {
        name: 'La Lacommandaise',
        place: 'Lacommande (64360)',
        date: 'Le 14 Aout 2022',
        about: '13km, 400m D+',
        results: ''
    },
    {
        name: 'Trail de la Maine',
        place: 'Aigrefeuille sur Maine -> Montaigu',
        date: 'Le 24 Septembre 2022',
        about: '22km, 315m D+',
        results: ''
    }
];

export default SPORTS;
