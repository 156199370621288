import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAppWidth } from '../../selectors/app';
import './index.css';

function CollapsePanel(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);
    const [actualHeight, setActualHeight] = useState('0');
    const blocRef = React.createRef();
    const { title, expand, width, children } = props;

    const setHeight = useCallback(() => {
        if (blocRef && blocRef.current) {
            const { height } = blocRef.current.getBoundingClientRect();
            const { scrollHeight } = blocRef.current;
            setActualHeight(isOpen ? `${height || scrollHeight}px` : '0');
        }
    }, [setActualHeight, isOpen, blocRef]);

    const updateHeight = useCallback(() => {
        setHeight();
        window.setTimeout(() => {
            setHeight();
        }, 10);
    }, [setHeight]);

    useEffect(() => {
        if (!isInitialised && expand) {
            setIsOpen(true);
            updateHeight();
            setIsInitialised(true);
        }
    }, [expand, setIsOpen, updateHeight, isInitialised, setIsInitialised]);

    useEffect(() => {
        updateHeight();
    }, [width, updateHeight]);

    function click() {
        setIsOpen(!isOpen);
        updateHeight();
    }

    return (
        <React.Fragment>
            <div className="row wrap mt-s">
                <div
                    className={`row wrap clickable ${isOpen ? 'active' : ''}`}
                    onClick={() => click()}
                >
                    <div className="row space-between align-center mb-xs">
                        <div className="col">
                            <h2>{title}</h2>
                        </div>
                        <div className="col">
                            {isOpen ? (
                                <i className="fas collapse-panel__toggle float-right mr-xs fa-minus-square" />
                            ) : (
                                <i className="fas collapse-panel__toggle float-right mr-xs fa-plus-square" />
                            )}
                        </div>
                    </div>
                    <div className="collapse-panel__line mt-xs mb-xs" />
                </div>
                <div
                    className="row wrap collapse-panel__details-bloc"
                    style={{ maxHeight: actualHeight }}
                >
                    <div ref={blocRef} className="full">
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

CollapsePanel.propTypes = {
    width: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.array,
    expand: PropTypes.bool
};

const mapStateToProps = (store) => ({
    width: getAppWidth(store)
});

export default connect(mapStateToProps)(CollapsePanel);
