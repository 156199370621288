import { WINDOW_RESIZE, WINDOW_SCROLL } from '../types/app';

const initialState = {
    width: 0,
    height: 0,
    scrollTop: 0
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case WINDOW_RESIZE:
            return {
                ...state,
                ...payload
            };
        case WINDOW_SCROLL:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}
