import React from 'react';
import PropTypes from 'prop-types';

export default function SimpleListItem(props) {
    const { item } = props;
    return (
        <React.Fragment>
            <div className="row mt-xs">
                ●&nbsp;
                {item.link ? (
                    <a
                        href={item.link}
                        className="link clickable hoverable"
                        rel="noopener noreferrer"
                        target="_blank"
                        title={item.name}
                    >
                        {item.name}
                    </a>
                ) : null}
                {!item.link ? <span>{item.name}</span> : null}
            </div>
        </React.Fragment>
    );
}

SimpleListItem.propTypes = {
    item: PropTypes.object.isRequired
};
