import React from 'react';
import HOBBIES from '../../texts/hobbies';

export default function CvHobbies() {
    const hobbies = HOBBIES.filter((hobby) => !hobby.custom && !hobby.hidden).slice(0, 4);

    return (
        <React.Fragment>
            <div className="row space-between mb-s">
                <div className="col full">
                    <h2 className="cv__part mb-s">Hobbies</h2>
                    {hobbies.map((hobby, index) => (
                        <div key={index} className="row mb-s">
                            <span>
                                <b>{hobby.text} :</b> {hobby.details}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
