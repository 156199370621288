import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CollapsePanel from '../CollapsePanel';
import Mission from '../Mission';
import { getDeltaBetweenDates, getYearsFromTo, parseDate } from '../../services';

export default function WorkExperience(props) {
    const location = useLocation();
    const { experience, expand } = props;
    const { startDate, endDate } = experience;
    const duration = getDeltaBetweenDates(startDate, endDate, 'm');
    const fromTo = getYearsFromTo(startDate, endDate);
    const link = `${window.location.origin}/#${location.pathname}?details=${experience.key}`;
    const missions = experience.missions.sort((a, b) =>
        parseDate(a.endDate) < parseDate(b.endDate) ? 1 : -1
    );

    return (
        <React.Fragment>
            <div className="row wrap mt-s">
                <CollapsePanel
                    expand={expand}
                    title={`${experience.title} @ ${experience.company}`}
                >
                    <div className="row full space-between">
                        <div className="col sub-title">{experience.company}</div>
                        <div className="col justify-center">
                            <a
                                className="sub-title link"
                                href={experience.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="experience.company"
                            >
                                <i className="fas fa-external-link-alt" />
                            </a>
                        </div>
                    </div>
                    <div className="location">{experience.location}</div>
                    <div className="date-text">
                        Durée : {fromTo}, {duration}{' '}
                    </div>
                    {missions.length > 0 ? (
                        <div className="mt-s justify">
                            <h3>Mes missions</h3>
                        </div>
                    ) : null}
                    {missions.length > 0 ? (
                        <div className="col full mt-s">
                            {missions.map((mission, index) => (
                                <Mission key={index} mission={mission} />
                            ))}
                        </div>
                    ) : null}
                    <div>
                        <a href={link} className="link" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-link" /> Permalien
                        </a>
                    </div>
                </CollapsePanel>
            </div>
        </React.Fragment>
    );
}

WorkExperience.propTypes = {
    experience: PropTypes.object.isRequired,
    expand: PropTypes.bool.isRequired
};
