const lang = {
    title: 'Langues',
    skills: [
        {
            name: 'Français',
            icon: 'fr',
            level: 5
        },
        {
            name: 'English',
            icon: 'gb',
            level: 2
        }
    ]
};

export default lang;
