import moment from 'moment';

const DATES = {
    units: {
        y: {
            divider: 1000 * 60 * 60 * 24 * 365.25, // ms * s * min * h * d
            text: {
                singular: 'an',
                plural: 'ans'
            }
        },
        m: {
            divider: 1000 * 60 * 60 * 24 * 30.4, // ms * s * min * h * (d/12)
            text: {
                singular: 'mois',
                plural: 'mois'
            }
        }
    }
};

export function parseDate(dateToParse) {
    return dateToParse !== 'now' ? moment(dateToParse, 'DD/MM/YYYY') : moment(new Date());
}

function getText(value, unit) {
    if (value === 0) {
        return '';
    }
    return `${value} ${
        value === 1 ? DATES.units[unit].text.singular : DATES.units[unit].text.plural
    } `;
}

function getDeltaBetweenDates(startDate, endDate = 'now', unit = 'y', displayCurrently = true) {
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    const diff = Math.floor(end.diff(start, 'months'));
    let text = '';

    if (end === 'now' && diff === 0) {
        text = 'Tout juste commencé';
    }
    const years = Math.floor(diff / 12);
    const months = Math.floor(diff % 12);

    if (unit === 'm') {
        text += getText(years, 'y') + getText(months, 'm');
    } else {
        text += getText(years, 'y');
    }

    if (endDate === 'now' && displayCurrently) {
        text += ' (en cours)';
    }
    return text;
}

function getYearsFromTo(startDate, endDate) {
    const start = parseDate(startDate);
    const end = parseDate(endDate);
    if (start.year() !== end.year()) {
        return `De ${start.year()} à ${end.year()}`;
    }
    return `Depuis ${start.year()}`;
}

function formatDate(date) {
    return `${date.month() + 1}/${date.year()}`;
}

function getDate(date) {
    return formatDate(parseDate(date));
}

export { getDeltaBetweenDates, getYearsFromTo, getDate };
