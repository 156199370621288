import React from 'react';
import { ALL } from '../../texts/skills';

export default function CvSkills() {
    const skills = ALL.map((group) => ({
        title: group.title,
        skills: group.skills
            .reduce((acc, skill) => {
                acc.push(skill.name);
                return acc;
            }, [])
            .sort()
    }));

    return (
        <React.Fragment>
            <div className="row space-between mb-s mobile-reverse">
                <div className="col full">
                    <h2 className="cv__part mb-s">Compétences</h2>
                    {skills.map((group, index) => (
                        <div key={index} className="row mb-s">
                            <span>
                                <b>{group.title} :</b> {group.skills.join(', ')}
                            </span>
                            .
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
