import React from 'react';
import { STUDIES } from '../../texts/studies';

export default function CvStudies() {
    return (
        <React.Fragment>
            <div className="row space-between mb-s mobile-reverse">
                <div className="col full">
                    <h2 className="cv__part mb-s">Formations</h2>
                    {STUDIES.map((study, index) => (
                        <div key={index} className="row mb-s space-between">
                            <div className="col">
                                <h3 className="row">{study.title}</h3>
                                <div className="row">{study.school}</div>
                                <div className="row location">{study.location}</div>
                            </div>
                            <div className="col">{study.graduationDate}</div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
