import React from 'react';
import SportItemList from '../components/SportItemList';

const READINGS = {
    name: 'reading',
    text: 'Lecture',
    details: "Grand lecteur de sciences fictions, dystopies ou encore romans d'anticipation.",
    listTitle: 'Derniers romans lu :',
    list: [
        {
            name: 'Les voyages de Gulliver - Jonathan Swift',
            link: 'https://fr.wikipedia.org/wiki/Les_Voyages_de_Gulliver'
        },
        {
            name: 'La horde du contrevent - Alain Damasio',
            link: 'https://fr.wikipedia.org/wiki/La_Horde_du_Contrevent'
        },
        {
            name: 'La ferme des animaux - George Orwell',
            link: 'https://fr.wikipedia.org/wiki/La_Ferme_des_animaux'
        },
        {
            name: 'Un bonheur insoutenable - Ira Levin',
            link: 'https://fr.wikipedia.org/wiki/Un_bonheur_insoutenable'
        },
        {
            name: '1984 - George Orwell',
            link: 'https://fr.wikipedia.org/wiki/1984_(roman)'
        }
    ]
};

const PHOTO = {
    name: 'photography',
    text: 'Photo',
    details:
        "J'apprécie principalement la recherche de scènes, la capture des instants et le post traitement. Permettant ainsi de retranscrire le plus fidèlement possible des moments de vie.",
    listTitle: 'Mon matériel actuel :',
    list: [
        { name: 'Canon R6' },
        { name: 'Sigma 50mm f/1.4 DG HSM | Art' },
        { name: 'Canon 10-18mm f/4.5-5.6' },
        { name: 'Sigma 18-300mm f/3.5-6.3 DG Macro OS HSM' },
        { name: 'Canon 16mm f/2.8' },
        { name: 'Sigma 150-600mm f5/6.3 DG OS HSM | Sport' }
    ]
};

const DEV = {
    name: 'development',
    text: 'Développement',
    details: `Réalisation occasionnelle d'outils pour moi ou ma compagne permettant d'automatiser des tâches longues et/ou fastidieuses.`,
    moreLink: '/side-projects',
    moreText: 'Voir la liste de mes projets'
};

const VIDEO_GAMES = {
    name: 'video-games',
    text: 'Jeux vidéos',
    details: 'Grand amateur de jeux vidéo toutes plateformes confondues.',
    listTitle: "Les derniers jeux auxquels j'ai joué :",
    hidden: true,
    list: [
        { name: 'Star Wars Jedi : Fallen Order' },
        { name: 'Days gone' },
        { name: 'Crash Team Racing Nitro-Fueled' },
        { name: "Assassin's creed : Origin" }
    ]
};

const CINEMA = {
    name: 'cinema',
    text: 'Cinéma',
    hidden: true,
    details:
        'Je suis un passionné de cinéma qui ne manque jamais les dernières sorties ou avant-premières.'
};

const SPORTS = {
    name: 'sports',
    text: 'Sport',
    custom: 'component',
    component: <SportItemList />
};

const HOBBIES = [
    {
        name: 'hobbies',
        text: 'Hobbies',
        custom: 'title'
    },
    READINGS,
    PHOTO,
    DEV,
    VIDEO_GAMES,
    CINEMA,
    SPORTS
];

export default HOBBIES;
