const frontend = {
    title: 'Frontend',
    skills: [
        {
            name: 'HTML',
            icon: 'html',
            level: 4
        },
        {
            name: 'CSS',
            icon: 'css',
            level: 3
        },
        {
            name: 'JavaScript',
            icon: 'js',
            level: 4
        },
        {
            name: 'TypeScript',
            icon: 'ts',
            level: 4
        },
        {
            name: 'Angular',
            icon: 'angular',
            level: 4
        },
        {
            name: 'AngularJS',
            icon: 'angularjs',
            level: 3
        },
        {
            name: 'React',
            icon: 'react',
            level: 4
        },
        {
            name: 'Redux',
            icon: 'redux',
            level: 2
        },
        {
            name: 'ReactNative',
            icon: 'react-native',
            level: 2
        },
        {
            name: 'Less',
            icon: 'less',
            level: 2
        },
        {
            name: 'Vue',
            icon: 'vue',
            level: 4
        },
        {
            name: 'VueX',
            icon: 'vue',
            level: 3
        }
    ]
};

export default frontend;
