import React, { useEffect } from 'react';
import generatePdf from '../../services/pdf';
import './index.css';
import CvPersonal from '../../components/CvPersonal';
import CvStudies from '../../components/CvStudies';
import CvWorkExperiences from '../../components/CvWorkExperiences';
import CvSkills from '../../components/CvSkills';
import CvHobbies from '../../components/CvHobbies';

export default function CV() {
    useEffect(() => {
        document.title = 'Morgan DENIS - CV';
    }, []);

    return (
        <React.Fragment>
            <div>
                <div className="row justify-center pt-s">
                    <button className="cv__download" onClick={() => generatePdf('pdf')}>
                        <div className="row">
                            <i className="fas fa-download mt-xs mr-s" />
                            <span className="cv__button-text">Télécharger mon CV</span>
                        </div>
                    </button>
                </div>
                <div id="pdf">
                    <CvPersonal />
                    <CvStudies />
                    <CvWorkExperiences />
                    <CvSkills />
                    <CvHobbies />
                </div>
            </div>
        </React.Fragment>
    );
}
