import { WINDOW_RESIZE, WINDOW_SCROLL } from '../types/app';

export const windowResize = (height, width) => ({
    type: WINDOW_RESIZE,
    payload: {
        height,
        width
    }
});

export const windowScroll = (scrollTop) => ({
    type: WINDOW_SCROLL,
    payload: {
        scrollTop
    }
});
