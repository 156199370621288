const dut = {
    key: 'dut',
    title: 'DUT informatique',
    school: 'Université de Picardie Jules Vernes',
    location: 'Amiens',
    link: 'http://info.iut-amiens.fr/',
    graduationDate: '2011',
    description: `Cette formation est à l'origine de la majorité de mes compétences techniques.
    C'est là que j'y ai appris l'algorithmie, la programmation orientée objet, l'administration système et le réseau dans lequel
     je me suis spécialisé en 2éme année.`,
    learnedSkills: ['Algorithmie', 'HTML', 'CSS', 'Javascript', 'C', 'Java', 'Shell']
};

export default dut;
