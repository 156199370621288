import React from 'react';
import PropTypes from 'prop-types';
import { getDeltaBetweenDates } from '../../services';

export default function InfoLineDate(props) {
    const date = getDeltaBetweenDates(props.text, 'now', 'y', false);
    return <span>{date}</span>;
}

InfoLineDate.propTypes = {
    text: PropTypes.string.isRequired
};
