import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAppWidth } from '../../selectors/app';
import LEVELS_EXPLANATION from '../../texts/levels-explanation';
import LevelIndicator from '../LevelIndicator';
import './index.css';

function SkillLegend(props) {
    const { width } = props;
    const [isFullText, setIsFullText] = useState(width > 700);

    useEffect(() => {
        setIsFullText(width > 700);
    }, [width]);

    return (
        <React.Fragment>
            <div className="col skill-legend__container">
                {LEVELS_EXPLANATION.map((explanation, index) => (
                    <div key={index} className="row">
                        <div className="col justify-center">
                            <div className="skill-legend__level-container flex space-evenly full">
                                <LevelIndicator level={index + 1} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="skill-legend__text-value">
                                {isFullText ? explanation.full : explanation.short}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

SkillLegend.propTypes = {
    width: PropTypes.number.isRequired
};

const mapStateToProps = (store) => ({
    width: getAppWidth(store)
});

export default connect(mapStateToProps)(SkillLegend);
