import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SimpleList from '../SimpleList';

export default function Hobby(props) {
    const nav = useNavigate();
    const { hobby } = props;

    function navigate() {
        if (hobby.moreLink) {
            nav(hobby.moreLink);
        }
    }

    return (
        <React.Fragment>
            <div className="col">
                <div className="row">
                    <h2>{hobby.text}</h2>
                </div>
                <div className="row">
                    <div
                        className="bloc mt-s justify"
                        dangerouslySetInnerHTML={{ __html: hobby.details }}
                    />
                </div>
                {hobby.list && hobby.listTitle ? (
                    <div className="row">
                        <SimpleList items={hobby.list} title={hobby.listTitle} />
                    </div>
                ) : null}
                {hobby.moreLink && hobby.moreText ? (
                    <div className="row mt-xs">
                        <div className="link" onClick={navigate}>
                            <i className="fas fa-link mr-xs" />
                            {hobby.moreText}
                        </div>
                    </div>
                ) : null}
                {hobby.custom === 'component' ? hobby.component : null}
            </div>
        </React.Fragment>
    );
}

Hobby.propTypes = {
    hobby: PropTypes.object.isRequired
};
