import React, { useState, useEffect, useCallback } from 'react';
import { HARD, SOFT } from '../../texts/skills';
import SkillLegend from '../../components/SkillLegend';
import SkillColumn from '../../components/SkillColumn';
import './index.css';

function sortFn(a, b) {
    if (a.level !== b.level) {
        return b.level - a.level;
    }
    return a.name > b.name ? 1 : -1;
}

export default function Skills() {
    const [hardSkills, setHardSkills] = useState(
        HARD.map((group) => ({
            ...group,
            filteredSkills: group.skills.sort(sortFn)
        }))
    );
    const [softSkills, setSoftSkills] = useState(
        SOFT.map((group) => ({
            ...group,
            filteredSkills: group.skills.sort(sortFn)
        }))
    );
    const [search, setSearch] = useState('');

    const filterFn = useCallback(
        (a) => {
            return a.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        },
        [search]
    );

    const searchSkills = useCallback(() => {
        setHardSkills(
            HARD.map((group) => ({
                ...group,
                filteredSkills: group.skills.filter((skills) => filterFn(skills)).sort(sortFn)
            }))
        );
        setSoftSkills(
            SOFT.map((group) => ({
                ...group,
                filteredSkills: group.skills.filter((skills) => filterFn(skills)).sort(sortFn)
            }))
        );
    }, [setHardSkills, setSoftSkills, filterFn]);

    useEffect(() => {
        document.title = 'Morgan DENIS - compétences';
    }, []);

    useEffect(() => {
        searchSkills();
    }, [search, searchSkills]);

    function onChange(event) {
        setSearch(event.target.value);
    }

    return (
        <React.Fragment>
            <div className="row wrap space-evenly">
                <div className="col flex-1">
                    <div className="row wrap justify-center">
                        <div className="col flex-1">
                            <input
                                type="text"
                                className="skills__search-input center"
                                placeholder="Recherche"
                                aria-label="Recherche"
                                value={search}
                                onChange={(event) => onChange(event)}
                            />
                        </div>
                    </div>
                    <div className="row wrap space-evenly">
                        {hardSkills.map((group, index) => {
                            if (group.filteredSkills.length > 0) {
                                return (
                                    <SkillColumn
                                        key={index}
                                        title={group.title}
                                        skills={group.filteredSkills}
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div className="row wrap justify-center">
                        {softSkills.map((group, index) => {
                            if (group.filteredSkills.length > 0) {
                                return (
                                    <SkillColumn
                                        key={index}
                                        title={group.title}
                                        skills={group.filteredSkills}
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
                <div className="row wrap justify-center">
                    <div className="col flex-1">
                        <h3 className="center">Légende</h3>
                        <SkillLegend />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
