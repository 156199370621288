const CSQ = {
    key: 'contentsquare',
    title: 'Développeur fullstack JS',
    company: 'ContentSquare',
    link: 'https://www.contentsquare.com/fr/',
    location: 'Paris',
    startDate: '01/12/2016',
    endDate: '15/03/2019',
    resume: 'Correction et amélioration du frontend et du backend de la plateforme. AngularJS/6, NodeJS.',
    missions: [
        {
            title: "Maintient de l'application",
            startDate: '01/12/2016',
            endDate: '15/03/2019',
            details: `Ayant été intégré au sein de la core team,
      j'ai été amené à maintenir l'application afin d'offrir au client une expérience toujours plus satisfaisante.<br/>
      Mes missions principales étaient :
      <ul>
        <li>Corriger les bugs front et back ;</li>
        <li>Faire des petites améliorations visuelles et fonctionnelles ;</li>
        <li>Migrer les composant d'AngularJS vers Angular.</li>
      </ul>`,
            technologies: [
                'AngularJS',
                'Angular',
                'HTML',
                'CSS',
                'JavaScript',
                'Typescript',
                'Less',
                'Hapi',
                'Sequelize'
            ]
        },
        {
            title: "Mise en place d'un service provider",
            startDate: '03/09/2018',
            endDate: '15/10/2018',
            details: `Dans l'optique d'ouverture de la solution contentsquare,
      nous avons mis en place une système de SSO qui va se brancher directement sur le provider de nos clients.
      <br/>Le gain est double :
      <ul>
        <li>Simplifier la gestion des utilisateurs pour nos clients ;</li>
        <li>Faire disparaitre les tâches répétitives de création de compte pour les employées et les clients.</li>
      </ul>
      Ce qui a été développé :
      <ul>
        <li>Un service provider basé sur la librairie saml2-js ;</li>
        <li>L'interconnection avec le système existant de login, afin de ne pas bloquer les utilisateur sans identity provider ;</li>
        <li>Une interface d'administration des différents providers en fonction des clients ;</li>
        <li>Le branchement avec Google360 afin d'unifier la gestion de nos comptes en interne ;</li>
        <li>Une refonte de l'interface de connexion en 2 étapes afin de gérer le nouveau mécanisme ;</li>
      </ul>`,
            technologies: [
                'HTML',
                'CSS',
                'Angular',
                'Typescript',
                'SAML2',
                'Google360',
                'NestJS',
                'SSO'
            ]
        },
        {
            title: "Développement d'une extension chrome permettant de remplacer des données à la volée",
            startDate: '01/05/2017',
            endDate: '01/07/2017',
            details: `Pour aider les commerciaux dans leur processus de démonstration, j'ai conçu et développé une extension chrome permettant de :
      <ul>
        <li>Modifier les données affichées en direct ;</li>
        <li>Enregistrer ces données ;</li>
        <li>Ne plus afficher les données d'origine lors des futures navigations.</li>
      </ul>
    C'est dans ce but que j'ai développé :
    <ul>
        <li>Une extension chrome permettant d'injecter du contenu dans la page ;</li>
        <li>Un micro service NodeJS permettant la gestion des données entrantes et sortantes.
        Avec proxyfication pour les endpoints non gérés.</li>
    </ul>`,
            technologies: ['HTML', 'CSS', 'JavaScript', 'Hapi', 'ChromeAPI']
        },
        {
            title: "Développement d'une API permettant l'intégration avec Adobe Analytics",
            startDate: '01/02/2018',
            endDate: '01/04/2018',
            details: `Dans le cadre de l'ouverture de l'application, j'ai été amené à me pencher sur l'intégration avec Adobe Analytcs.<br/>
    Les différents objectifs de cette mission étaient :
    <ul>
        <li>S'authentifier chez Adobe ;</li>
        <li>Configurer l'interaction grâce aux identifiants utilisateurs via le protocole WSSE ;</li>
        <li>Exposer différents endpoints d'Adobe de manière transparante pour le consommateur de l'API ;</li>
        <li>Préparer l'intégration avec Google Analytics en généralisant au maximum le code.</li>
    </ul>`,
            technologies: ['Koa', 'Sequelize', 'AdobeAnalyticsAPI', 'WSSE']
        }
    ]
};

export default CSQ;
