import React from 'react';
import './index.css';
import { menuRoutes } from '../../../services/routes';
import DesktopMenuLink from './DesktopMenuLink';

function HeaderDesktop() {
    const routes = menuRoutes();
    return (
        <div className="row header-desktop">
            <div className="row menu justify-center">
                {routes
                    .filter((route) => route.name)
                    .map((route, i) => (
                        <DesktopMenuLink key={i} {...route} isLast={i === routes.length - 1} />
                    ))}
            </div>
        </div>
    );
}

export default HeaderDesktop;
