import React from 'react';
import SportItem from '../SportItem';
import SPORTS from '../../texts/sports';

export default function SportItemList() {
    return (
        <React.Fragment>
            {SPORTS.reverse().map((sport, i) => (
                <SportItem key={i} {...sport} />
            ))}
        </React.Fragment>
    );
}
