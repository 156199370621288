import React from 'react';
import './index.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { routes } from '../../services/routes';

function MainContainer() {
    return (
        <div className="main-container flex full justify-center">
            <div className="main-container__wrapper justify-center">
                <Routes>
                    {routes.map((route, i) => (
                        <Route key={i} {...route} />
                    ))}
                    <Route path="*" element={<Navigate to="/me" replace />} />
                </Routes>
            </div>
        </div>
    );
}

export default MainContainer;
