const ARISMORE = {
    key: 'arismore',
    title: 'Intégrateur solution',
    company: 'Arismore',
    startDate: '01/02/2014',
    endDate: '01/12/2016',
    location: 'Saint Cloud',
    link: 'https://www.arismore.fr/',
    resume: "Développement d'une interface de supervision de réseau. AngularJS.",
    missions: [
        {
            title: "Dévelopement d'une interface de supervision de réseau",
            details: `Pour un grand nom des télécommunications en France,
      j'ai travaillé à la conception et à la réalisation d'une application de monitoring de leur réseau.<br/>
      Plusieurs aspects ont été au cœur de la réalisation :
        <ul>
          <li>Afficher une grande quantité de données dans un minimum d'espace ;</li>
          <li>Rendre la lecture des informations aussi simple que possible ;</li>
          <li>Permettre aux utilisateurs experts de pouvoir gérer leurs sources de données depuis une interface d'administration.</li>
        </ul>`,
            startDate: '01/02/2014',
            endDate: '01/02/2016',
            technologies: ['AngularJS', 'HTML', 'JavaScript', 'CSS']
        },
        {
            title: "Dévelopement d'une application web de dématerialisation de contrats bancaires",
            details: `Dans le cadre du processus de dématérialisation des documents banquaires,
      je suis intervenu afin de les aider à concevoir et développer une application leur permettant de remplacer les documents papier.
      <br/>
      Mes missions ont été :
        <ul>
          <li>Définir le besoin avec le client ;</li>
          <li>Déterminer les diférents documents à intégrer dans la première version ;</li>
          <li>Implémenter la première version ;</li>
          <li>Intégrer le processus de signature de documents d'OpenTrust.</li>
        </ul>`,
            startDate: '01/03/2016',
            endDate: '01/07/2016',
            technologies: ['AngularJS', 'HTML', 'JavaScript', 'CSS', 'Signature éléctronique']
        },
        {
            title: "Dévelopement d'une application backoffice pour la gestion de véhicules connectés",
            details: `Au sein de Memority, une filliale d'Arismore,
      j'ai participé à la réalisation d'un backoffice en partenariat avec un grand compte de l'automobile.<br/>
        Voici les missions que j'ai accompli :
        <ul>
            <li>Choix technologiques pour assurer la pérénité d'une solution à long terme ;</li>
            <li>Développement en collaboration avec des développeurs travaillant pour le partenaire ;</li>
            <li>Discussions autour de l'ergonomie des composants ainsi que de leur standardisation ;</li>
            <li>Documentation complète de la solution.</li>
</ul>`,
            startDate: '01/07/2016',
            endDate: '01/12/2016',
            technologies: ['Angular', 'HTML', 'TypeScript', 'CSS']
        }
    ]
};

export default ARISMORE;
