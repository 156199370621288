import { EXPERIENCES } from './experiences';
import app from '../../package.json';
import FULL_NAME from './me';

const PERSONAL = [
    {
        name: 'me',
        custom: 'title',
        text: FULL_NAME,
        isBig: true
    },
    {
        name: 'job-title',
        custom: 'title',
        text: `${EXPERIENCES[0].title} @ ${EXPERIENCES[0].company}`
    },
    {
        name: 'birth-date',
        icon: 'fa-user',
        text: '05/12/1990', // us date format
        custom: 'date'
    },
    {
        name: 'boy-birth',
        icon: 'fa-baby',
        text: '24/03/2020',
        custom: 'birth-date'
    },
    {
        name: 'nationality',
        icon: 'fa-flag',
        text: 'Français'
    },
    {
        name: 'driving-license',
        icon: 'fa-car-alt',
        text: 'Permis B'
    },
    {
        name: 'phone',
        icon: 'fa-mobile-alt',
        text: '06-28-05-27-45'
    },
    {
        name: 'mail',
        icon: 'fa-at',
        text: 'morgan.denis.60@gmail.com',
        custom: 'mail'
    },
    {
        name: 'github-account',
        icon: 'fa-github-alt',
        text: 'Profil Github',
        link: 'https://github.com/vulcaryn',
        isBrandIcon: true,
        custom: 'link'
    },
    {
        name: 'twitter-account',
        icon: 'fa-twitter',
        text: 'Profil Twitter',
        link: 'https://twitter.com/Vulcaryn',
        isBrandIcon: true,
        custom: 'link'
    },
    {
        name: 'linkedin-account',
        icon: 'fa-linkedin-in',
        text: 'Profil LinkendIn',
        link: 'https://www.linkedin.com/in/morgan-denis-0132b479/',
        isBrandIcon: true,
        custom: 'link'
    },
    {
        name: 'app-version',
        icon: 'fa-code',
        text: `Version ${app.version} - React`
    }
];

export default PERSONAL;
