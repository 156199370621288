import ARISMORE from './experience/arismore';
import CSQ from './experience/contentsquare';
import AMPLEMENT from './experience/amplement';

const EXPERIENCES = [AMPLEMENT, CSQ, ARISMORE];
const resumeTitle = { name: 'experiences', text: 'Mes expériences', custom: 'title' };
const EXPERIENCES_RESUME = [resumeTitle].concat(
    EXPERIENCES.map((e, i) => ({ name: `experience-${i}`, text: `${e.title} @ ${e.company}` }))
);

export { EXPERIENCES, EXPERIENCES_RESUME };
