import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderDesktop from './Desktop';
import HeaderMobile from './Mobile';
import './index.css';
import { getScrollTop } from '../../selectors/app';

function Header(props) {
    const { scrollTop } = props;
    let headerClasses = 'col header header__shadowed justify-center';
    if (scrollTop > 10) {
        headerClasses += ' header__shadowed-overflowing';
    }
    return (
        <div className={headerClasses}>
            <nav className="header__desktop">
                <HeaderDesktop />
            </nav>
            <nav className="header__mobile">
                <HeaderMobile />
            </nav>
        </div>
    );
}

Header.propTypes = {
    scrollTop: PropTypes.number.isRequired
};

const mapStateToProps = (store) => ({
    scrollTop: getScrollTop(store)
});

export default connect(mapStateToProps)(Header);
