import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CollapsePanel from '../CollapsePanel';
import TechList from '../TechList';

export default function Study(props) {
    const location = useLocation();
    const { study, expand } = props;
    const link = `${window.location.origin}/#${location.pathname}?details=${study.key}`;

    return (
        <React.Fragment>
            <div className="row wrap mt-s">
                <CollapsePanel expand={expand} title={study.title}>
                    <div className="row space-between">
                        <div className="col sub-title">{study.school}</div>
                        <div className="col justify-center">
                            <a
                                className="sub-title link"
                                href={study.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={study.school}
                            >
                                <i className="fas fa-external-link-alt" />
                            </a>
                        </div>
                    </div>
                    <div className="location">{study.location}</div>
                    <div className="date-text">Diplômé en {study.graduationDate}</div>
                    <div
                        className="mt-s justify"
                        dangerouslySetInnerHTML={{ __html: study.description }}
                    />
                    <div>
                        <TechList technologies={study.learnedSkills} />
                    </div>
                    <div>
                        <a href={link} className="link" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-link" /> Permalien
                        </a>
                    </div>
                </CollapsePanel>
            </div>
        </React.Fragment>
    );
}

Study.propTypes = {
    study: PropTypes.object.isRequired,
    expand: PropTypes.bool.isRequired
};
