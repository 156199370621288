import SNAKE from './side-project/snake';
import RABBIT from './side-project/rabbit';
import SPENDINGS from './side-project/spendings';
import KOA_HEALTHCHECK from './side-project/koa-healthcheck';
import COOK from './side-project/cook';
import GITLAB_HABITICA_WEBHOOK from './side-project/gitlab-habitica-webhook';
import HOME from './side-project/home';
import BIRTH from './side-project/birth';
import BABY from './side-project/baby';

const SIDE_PROJECTS = [
    BIRTH,
    COOK,
    BABY,
    HOME,
    KOA_HEALTHCHECK,
    GITLAB_HABITICA_WEBHOOK,
    SNAKE,
    RABBIT,
    SPENDINGS
];
const SIDE_PROJECTS_RESUME = [
    {
        name: 'side-projects',
        text: 'Projet personnels',
        custom: 'title'
    },
    {
        name: 'side-projects-game',
        text: 'Jeux'
    },
    {
        name: 'side-projects-tools',
        text: 'Outils'
    }
];

export { SIDE_PROJECTS, SIDE_PROJECTS_RESUME };
