const database = {
    title: 'Database',
    skills: [
        {
            name: 'MySql',
            icon: 'mysql',
            level: 3
        },
        {
            name: 'PostgreSQL',
            icon: 'pgsql',
            level: 2
        },
        {
            name: 'MongoDB',
            icon: 'mongodb',
            level: 3
        },
        {
            name: 'Firebase',
            icon: 'firebase',
            level: 4
        },
        {
            name: 'RethinkDB',
            icon: 'rethinkdb',
            level: 4
        },
        {
            name: 'ElasticSearch',
            icon: 'elasticsearch',
            level: 2
        }
    ]
};

export default database;
