import React from 'react';
import PropTypes from 'prop-types';
import TechList from '../TechList';
import './index.css';

export default function SideProject(props) {
    const { project } = props;
    return (
        <React.Fragment>
            <div className="col">
                <div className="row">
                    <h2>{project.name}</h2>
                </div>
                <div className="row">
                    <div
                        className="bloc mt-s justify"
                        dangerouslySetInnerHTML={{ __html: project.description }}
                    />
                </div>
                <div className="row">
                    <div className="bloc">
                        <TechList technologies={project.technologies} />
                    </div>
                </div>
                {project.url ? (
                    <div className="row">
                        <div className="bloc">
                            <a
                                href={project.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link"
                            >
                                <i className="fas fa-link" /> Disponible ici
                            </a>
                        </div>
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
}

SideProject.propTypes = {
    project: PropTypes.object.isRequired
};
