import React from 'react';
import PropTypes from 'prop-types';
import SkillItem from '../SkillItem';

export default function SkillColumn(props) {
    const { title, skills } = props;
    return (
        <React.Fragment>
            <div className="flex">
                <div className="col">
                    <div>
                        <h2 className="flex justify-center">{title}</h2>
                        {skills.map((skill, index) => (
                            <SkillItem key={index} skill={skill} />
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

SkillColumn.propTypes = {
    title: PropTypes.string.isRequired,
    skills: PropTypes.array.isRequired
};
