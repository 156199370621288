import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import InfoLine from '../InfoLine';

export default function InfoBloc(props) {
    const { arrow, data, clickEvent } = props;

    function handleClick() {
        if (clickEvent) {
            clickEvent();
        }
    }

    return (
        <React.Fragment>
            <div
                className={`info-block ${arrow ? 'info-block_hoverable' : ''}`}
                onClick={() => handleClick()}
            >
                <div className="flex space-between">
                    <div className="content-block full">
                        {data.map((info, index) => (
                            <InfoLine
                                text={info.text}
                                custom={info.custom}
                                icon={info.icon}
                                isBrandIcon={info.isBrandIcon}
                                isBig={info.isBig}
                                link={info.link}
                                key={index}
                            />
                        ))}
                    </div>
                    {arrow ? (
                        <div className="float-right info-block__arrow center">
                            <i className="fas fa-arrow-right" />
                        </div>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
}
InfoBloc.propTypes = {
    arrow: PropTypes.bool,
    data: PropTypes.array.isRequired,
    clickEvent: PropTypes.func
};
