import React from 'react';
import PropTypes from 'prop-types';
import TechItem from '../TechItem';

export default function TechList(props) {
    const { technologies } = props;
    return (
        <React.Fragment>
            <div className="col">
                <div className="row wrap p-s">
                    {technologies.map((technology, index) => (
                        <TechItem technology={technology} key={index} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

TechList.propTypes = {
    technologies: PropTypes.array.isRequired
};
