const KOA_HEALTHCHECK = {
    name: 'Koa healtcheck',
    description:
        "Un middleware pour koaJS permettant d'ajouter automatiquement des endpoints de monitoring.",
    technologies: ['Javascript', 'Koa'],
    url: 'https://www.npmjs.com/package/koa-healthcheck',
    firstReleaseDate: '26/01/2018'
};

export default KOA_HEALTHCHECK;
