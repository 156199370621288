const misc = {
    title: 'Divers',
    skills: [
        {
            name: 'GIT',
            icon: 'git',
            level: 3
        },
        {
            name: 'GitLab',
            icon: 'gitlab',
            level: 4
        },
        {
            name: 'GitHub',
            icon: 'github',
            level: 4
        },
        {
            name: 'Jenkins',
            icon: 'jenkins',
            level: 2
        },
        {
            name: 'Docker',
            icon: 'docker',
            level: 2
        },
        {
            name: 'Arduino',
            icon: 'arduino',
            level: 2
        },
        {
            name: 'Jest',
            icon: 'jest',
            level: 3
        }
    ]
};

export default misc;
