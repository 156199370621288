import React from 'react';
import './index.css';
import PERSONAL from '../../texts/personal';
export default function CvPersonal() {
    const personalFields = ['github-account', 'linkedin-account', 'twitter-account'];
    const personal = PERSONAL.filter((info) => personalFields.includes(info.name));
    const mail = PERSONAL.find((info) => info.name === 'mail');
    const name = PERSONAL.find((info) => info.name === 'me').text;
    const phone = PERSONAL.find((info) => info.name === 'phone');
    const jobPosition = PERSONAL.find((info) => info.name === 'job-title').text;

    return (
        <React.Fragment>
            <div className="row space-between mb-s">
                <div className="col">
                    <div className="row">
                        <h1 className="cv-personal__name">{name}</h1>
                    </div>
                    <div className="row">
                        <h3 className="cv-personal__position">{jobPosition}</h3>
                    </div>
                    <div className="row">
                        <h4 className="mt-xs">
                            <a href={`mailto:${mail.text}`} className="cv-personal__link">
                                {mail.text}
                            </a>
                        </h4>
                    </div>
                    <div className="row">
                        <h4 className="mt-xs cv-personal__phone-number">{phone.text}</h4>
                    </div>
                </div>
                <div className="col pt-m">
                    {personal.map((info, index) => (
                        <div key={index} className="row mb-xs flex-end">
                            {info.link ? (
                                <a href={info.link} className="cv-personal__link">
                                    {info.text.split(' ')[1]}{' '}
                                    <i className={`fab ml-xs ${info.icon}`} />
                                </a>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
