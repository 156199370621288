import React, { useState } from 'react';
import './index.css';
import { menuRoutes } from '../../../services/routes';
import MobileMenuLink from './MobileMenuLink';

function HeaderMobile() {
    const routes = menuRoutes();
    const [open, setOpen] = useState(false);

    function toggleMenu() {
        setOpen(!open);
    }

    function onNavigate() {
        setOpen(false);
    }

    return (
        <div className="col full">
            <div className="row header-mobile__head justify-center">
                <div
                    className="header-mobile__menu-toggle-container row justify-center clickable center"
                    onClick={() => toggleMenu()}
                >
                    <div className="header-mobile__menu-icon col justify-center mr-s">
                        <i className={`fas ${open ? 'fa-times' : 'fa-bars'}`} />
                    </div>
                    <div className="col justify-center">Menu</div>
                </div>
            </div>
            <div
                className={`row header-mobile__menu ${
                    open ? 'header-mobile__opened' : 'header-mobile__closed'
                }`}
            >
                <div className="full">
                    {routes
                        .filter((route) => route.name)
                        .map((route, i) => (
                            <MobileMenuLink key={i} {...route} onNavigate={onNavigate} />
                        ))}
                </div>
            </div>
        </div>
    );
}

export default HeaderMobile;
