import * as html2pdf from 'html2pdf.js';

const options = {
    image: {
        type: 'jpeg',
        quality: 1
    },
    margin: [4, 6, 4, 6],
    filename: 'CV-DENIS_Morgan.pdf',
    html2canvas: { dpi: 192, scale: 2 },
    jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
        title: 'Morgan DENIS - CV',
        subject: 'CV',
        author: 'Morgan DENIS',
        keywords: 'Morgan DENIS, CV, Fullstack JS',
        creator: 'Morgan Denis'
    }
};

const generatePdf = async (element) => {
    const htmlElement = document.getElementById(element);
    html2pdf().set(options).from(htmlElement).save();
};

export default generatePdf;
