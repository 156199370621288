import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function TechItem(props) {
    const { technology } = props;
    return (
        <React.Fragment>
            <div className="col tech-item__col">
                <div className="tech-item__technology m-xs pr-s pl-m">
                    <div className="tech-item__round" />
                    <div className="text">{technology}</div>
                </div>
            </div>
        </React.Fragment>
    );
}

TechItem.propTypes = {
    technology: PropTypes.string.isRequired
};
