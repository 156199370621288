import React, { useEffect } from 'react';
import SideProject from '../../components/SideProject';
import { SIDE_PROJECTS } from '../../texts/side-projects';
import { parseDate } from '../../services';

export default function SideProjects() {
    const projects = SIDE_PROJECTS.sort((a, b) =>
        parseDate(a.firstReleaseDate) < parseDate(b.firstReleaseDate) ? 1 : -1
    );
    useEffect(() => {
        document.title = 'Morgan DENIS - projets';
    }, []);

    return (
        <React.Fragment>
            <div className="col pb-m">
                {projects.map((project, index) => (
                    <div key={index} className="row mt-m">
                        <SideProject project={project} />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}
