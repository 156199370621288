import { Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

function DesktopMenuLink({ path, name, isLast }) {
    const match = useMatch({
        path
    });

    return (
        <Link to={path}>
            <div className="flex">
                <div className={`header-desktop__menu-item ${match ? 'active' : ''}`}>{name}</div>
                {!isLast ? <div className="header-desktop__menu-separator" /> : null}
            </div>
        </Link>
    );
}

DesktopMenuLink.propTypes = {
    path: PropTypes.string.isRequired,
    isLast: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired
};

export default DesktopMenuLink;
