import React from 'react';
import PropTypes from 'prop-types';
import TechList from '../TechList';
import './index.css';
import { getDeltaBetweenDates } from '../../services';

export default function Mission(props) {
    const { mission } = props;
    if (!mission) return null;

    const duration = getDeltaBetweenDates(mission.startDate, mission.endDate, 'm');
    return (
        <React.Fragment>
            <div className="row">
                <div className="mission__container full">
                    <div className="row">
                        <h4>{mission.title}</h4>
                    </div>
                    <div className="row date-text">Durée : {duration}</div>
                    <div
                        className="col mt-s"
                        dangerouslySetInnerHTML={{ __html: mission.details }}
                    />
                    <TechList technologies={mission.technologies} className="row" />
                    <div className="mission__line" />
                </div>
            </div>
        </React.Fragment>
    );
}

Mission.propTypes = {
    mission: PropTypes.object.isRequired
};
