import React from 'react';
import PropTypes from 'prop-types';
import SimpleListItem from '../SimpleListItem';

export default function SimpleList(props) {
    const { items, title } = props;
    return (
        <React.Fragment>
            <div className="col full">
                <div className="row mt-s">
                    <h4>{title}</h4>
                </div>
                <div className="row">
                    <div className="col pl-s">
                        {items.map((item, index) => (
                            <SimpleListItem key={index} item={item} />
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

SimpleList.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
};
