import React from 'react';
import Home from '../screens/Home';
import Studies from '../screens/Studies';
import WorkExperiences from '../screens/WorkExperiences';
import Skills from '../screens/Skills';
import SideProjects from '../screens/SideProjects';
import Hobbies from '../screens/Hobbies';
import CV from '../screens/CV';

export const routes = [
    {
        path: '/me',
        element: <Home />,
        name: 'Moi'
    },
    {
        path: '/studies',
        element: <Studies />,
        name: 'Formations'
    },
    {
        path: '/experiences',
        element: <WorkExperiences />,
        name: 'Expériences'
    },
    {
        path: '/skills',
        element: <Skills />,
        name: 'Compétences'
    },
    {
        path: '/side-projects',
        element: <SideProjects />,
        name: 'Projets'
    },
    {
        path: '/hobbies',
        element: <Hobbies />,
        name: 'Loisirs'
    },
    {
        path: '/cv',
        element: <CV />,
        name: 'CV'
    }
];

export const menuRoutes = () => routes.filter((route) => route.name);
