import React, { useEffect } from 'react';
import { STUDIES } from '../../texts/studies';
import Study from '../../components/Study';
import useExpandDetails from '../../hooks/useExpandDetails';

export default function Studies() {
    const details = useExpandDetails();

    useEffect(() => {
        document.title = 'Morgan DENIS - formations';
    }, []);

    return (
        <React.Fragment>
            <div className="row wrap pb-m">
                <div className="col">
                    {STUDIES.map((study, index) => (
                        <div key={index} className="row">
                            <Study
                                study={study}
                                expand={
                                    (details && details === study.key) || (!details && index === 0)
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
