import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import InfoLineDate from '../InfoLineDate';
import InfoLineLink from '../InfoLineLink';
import InfoLineMail from '../InfoLineMail';
import InfoLineTitle from '../InfoLineTitle';
import InfoLineBirthDate from '../InfoLineBirthDate';

export default function InfoLine(props) {
    const { custom, icon, text, isBig, link, isBrandIcon } = props;
    const type = custom || 'default';
    return (
        <React.Fragment>
            <div className={`row ${type !== 'title' ? 'mt-s' : ''}`}>
                {type !== 'title' ? (
                    <div className="info-line__icon-container row justify-center">
                        {icon && !isBrandIcon ? <i className={`fas ${icon}`} /> : null}
                        {icon && isBrandIcon ? <i className={`fab ${icon}`} /> : null}
                    </div>
                ) : null}
                <div className="col justify-center ml-s full">
                    {type === 'date' ? <InfoLineDate text={text} /> : null}
                    {type === 'birth-date' ? <InfoLineBirthDate text={text} /> : null}
                    {type === 'link' ? <InfoLineLink text={text} link={link} /> : null}
                    {type === 'mail' ? <InfoLineMail text={text} /> : null}
                    {type === 'title' ? <InfoLineTitle text={text} isBig={isBig} /> : null}
                    {type === 'default' ? <span>{text}</span> : null}
                </div>
            </div>
        </React.Fragment>
    );
}

InfoLine.propTypes = {
    text: PropTypes.string.isRequired,
    custom: PropTypes.string,
    icon: PropTypes.string,
    isBrandIcon: PropTypes.bool,
    isBig: PropTypes.bool,
    link: PropTypes.string
};
