import React from 'react';
import PropTypes from 'prop-types';

export default function InfoLineLink(props) {
    const { text, link } = props;
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="link">
            {text}
        </a>
    );
}

InfoLineLink.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};
