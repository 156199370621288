const AMPLEMENT = {
    key: 'amplement',
    title: 'Lead dev backend',
    company: 'Amplement',
    startDate: '18/03/2019',
    endDate: 'now',
    location: 'Boulogne Billancourt',
    link: 'https://www.amplement.com/fr/',
    resume: 'Développement de la plateforme afin de répondre à de nouveaux enjeux de scalabilité et de performances. NodeJS.',
    missions: [
        {
            title: "Amélioration de l'API",
            startDate: '18/03/2019',
            endDate: 'now',
            details: `Rattaché au backend, je participe activement à l'évolution de l'API.<br/>
      Mes missions principales sont les suivantes :
      <ul>
        <li>Corriger les bugs ;</li>
        <li>Concevoir et architecturer la gestion du versioning de l'API ;</li>
        <li>Maintenir à jour les dépendances de l'application ;</li>
        <li>Concevoir et implémenter le découpage orienté micro services ;</li>
        <li>Refonte du système d'authentification.</li>
      </ul>`,
            technologies: ['ExpressJS', 'RethinkDB', 'Websocket', 'Webpack', 'Elasticsearch']
        },
        {
            title: 'Dévelopement mobile',
            startDate: '15/04/2019',
            endDate: '02/08/2019',
            details: `Suite au départ prématuré des développeurs mobile, je me suis occupé de finir la nouvelle version de l'application mobile et d'y intégrer les nouvelles features clés.<br/>
      L'ensemble est écrit avec ReactNative et doit être intégralement compatible avec iOS et Android.`,
            technologies: ['WebRTC', 'ReactNative', 'iOS', 'Android', 'Redux']
        },
        {
            title: 'Système de gestion des push notifications',
            startDate: '19/08/2019',
            endDate: '08/11/2019',
            details: `Dans l'optique de découpler les différents services de notre stack, j'ai conçu un service permettant par le biais de notre message broker d'envoyer les notification push à nos clients.<br/>
      Ce service permet entre autres de :<br/>
      <ul>
        <li>Choisir le service de notification à utiliser ;</li>
        <li>Ajouter un délai à l'envoi de la notification ;</li>
        <li>Gérer simplement les traductions, le groupement et l'affichage des notifications.</li>
      </ul>`,
            technologies: ['NodeJS', 'Firebase Cloud Messaging', 'OneSignal', 'NATS']
        },
        {
            title: `Découplage de la partie triggers de la base de donnée`,
            startDate: '18/05/2020',
            endDate: '20/07/2020',
            details: `Afin de simplifier la possibilité d'avoir plusieurs instances de notre API, il fallait découpler les triggers de base de donnée du reste du code.<br/>
Et cela pour plusieurs raisons :<br/>
<ul>
<li>Tout d'abord afin de ne pas avoir des triggers déclenchés autant de fois qu'il y a d'instances ;</li>
<li>Simplifier l'ajout et la suppression de triggers sans avoir à redémarrer l'API principale ;</li>
<li>Pouvoir brancher n'importe quel service sur n'importe quel trigger rendant ainsi le système plus souple.</li>
</ul>
`,
            technologies: ['NodeJS', 'RethinkDB', 'NATS']
        },
        {
            title: `Réalisation de 2 packages node privés`,
            startDate: '18/05/2020',
            endDate: '19/06/2020',
            details: `Dans le but d'éviter au maximum des doublons de codes ainsi que des erreurs de synchronisation entre les différents services, j'ai réalisé 2 packages node.<br/><br/>
<ul>
<li>Le premier concentre toute la logique de connexion au message broker, ainsi que toutes les constantes et les outils associés ;</li>
<li>Et le second rassemble les outils communs à nos différents services, tels que le logger, la logique de traçage de bugs et toute constante partagée entre les services.</li>
</ul>
L'ensemble hébergé sur le repository privé de github et est directement branché sur la CI afin de publier les versions du package dès qu'un tag est posé.`,
            technologies: ['NodeJS', 'NPM', 'NATS', 'Sentry', 'Github', 'CircleCI']
        },
        {
            title: `Refonte de la mécanique des conférences afin d'utiliser un server SFU`,
            startDate: '15/06/2020',
            endDate: '15/11/2020',
            details: `Afin d'offrir de meilleurs performances et de supporter plus de participants dans nos conférences, nous avons intégré un serveur SFU pour mutualiser les flux montants.
            <br/>Ce qui a été réalisé :
<ul>
<li>Refonte du workflow des conférences ;</li>
<li>Centralisation de la logique de gestion des peer connections coté serveur ;</li>
<li>Gestion des problèmes de connexions clients et ajustement de la qualité des flux ;</li>
<li>Unification des logiques pour les différentes mécaniques de communication (MESH, SFU, SIP).</li>
</ul>`,
            technologies: ['NodeJS', 'NATS', 'Redis']
        }
    ]
};

export default AMPLEMENT;
