const master = {
    key: 'ensc',
    title: "Diplôme d'ingénieur en cognitique",
    school: 'Ecole Nationale Supérieur de Cognitique (ENSC)',
    location: 'Bordeaux',
    link: 'https://ensc.bordeaux-inp.fr/fr',
    graduationDate: '2014',
    description: `Avec l'envie de conjuger Informatique et utilisabilité,
   j'ai choisi cette école afin d'intégrer l'être humain au cœur des nouvelles technologies.<br/>
   Cette formation me permet de penser à l'utilisabilité avant de concevoir une interface ou même un système.
  <br/><br/>
  L'UX a du sens au delà des interfaces clients. Il a parfaitement sa place dans la conception et la réalisation de toutes les couches d'une application :<br/>
<ul>
<li><b>Code</b> : la lecture du code doit être simple et rapide dans le plus de cas possible grâce entre autre au découpage par fonctions, fichiers et sujets ;</li>
<li><b>API</b> : les méthodes et les routes utilisables se doivent d'être logiques et intuitives, les résultats structurés et constants ;</li>
<li><b>Erreurs</b> : lorsqu'une erreur survient, elle doit au maximum être utilisable, avec du contexte en plus de la trace ;</li>
<li><b>DB</b> : la base de donnée doit être un reflet cohérent de ce qu'elle contient et ne pas cacher des informations derrière des noms incohérents.</li>
</ul>`,
    learnedSkills: [
        'Ergonomie',
        'Facteur Humain',
        'UX',
        'Intelligence artificielle',
        'C#',
        'HTML',
        'CSS',
        'Javascript',
        'Mogre',
        'Prolog'
    ]
};
export default master;
