import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PERSONAL from '../../texts/personal';
import InfoBloc from '../../components/InfoBloc';
import { STUDIES_RESUME } from '../../texts/studies';
import { EXPERIENCES_RESUME } from '../../texts/experiences';
import { SKILLS_RESUME } from '../../texts/skills';
import { SIDE_PROJECTS_RESUME } from '../../texts/side-projects';
import HOBBIES from '../../texts/hobbies';
import './index.css';

export default function Home() {
    const nav = useNavigate();

    useEffect(() => {
        document.title = 'Morgan DENIS';
    }, []);

    function navigate(path) {
        nav(path);
    }

    return (
        <div className="row home__switchable-row pb-s">
            <div className="col home__col">
                <div className="photo" />
                <InfoBloc aria-label="Informations personnelles" data={PERSONAL} />
            </div>
            <div className="col home__col">
                <InfoBloc
                    data={STUDIES_RESUME}
                    aria-label="Résumé de mes études"
                    arrow={true}
                    clickEvent={() => navigate('/studies')}
                />
                <InfoBloc
                    data={EXPERIENCES_RESUME}
                    aria-label="Résumé de mes expériences"
                    arrow={true}
                    clickEvent={() => navigate('/experiences')}
                />
                <InfoBloc
                    data={SKILLS_RESUME}
                    aria-label="Résumé de mes compétences"
                    arrow={true}
                    clickEvent={() => navigate('/skills')}
                />
                <InfoBloc
                    data={SIDE_PROJECTS_RESUME}
                    aria-label="Résumé de mes projets personnels"
                    arrow={true}
                    clickEvent={() => navigate('/side-projects')}
                />
                <InfoBloc
                    data={HOBBIES.slice(0, 4)}
                    aria-label="Mes hobbies"
                    arrow={true}
                    clickEvent={() => navigate('/hobbies')}
                />
            </div>
        </div>
    );
}
