import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function LevelIndicator(props) {
    const [activatedLevel, setActivatedLevel] = useState(0);
    const { level } = props;
    const timeout = useRef(0);

    useEffect(() => {
        timeout.current = window.setTimeout(() => setActivatedLevel(level));
    });

    useEffect(() => {
        return () => {
            if (timeout.current) {
                window.clearTimeout(timeout.current);
            }
        };
    });

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="flex p-xs full">
                        <div
                            className={`level-indicator__point ${
                                activatedLevel >= 1 ? 'level-indicator__point__level-1__active' : ''
                            }`}
                        >
                            <div className="level-indicator__sub-point" />
                        </div>
                        <div
                            className={`level-indicator__point ${
                                activatedLevel >= 2 ? 'level-indicator__point__level-2__active' : ''
                            }`}
                        >
                            <div className="level-indicator__sub-point" />
                        </div>
                        <div
                            className={`level-indicator__point ${
                                activatedLevel >= 3 ? 'level-indicator__point__level-3__active' : ''
                            }`}
                        >
                            <div className="level-indicator__sub-point" />
                        </div>
                        <div
                            className={`level-indicator__point ${
                                activatedLevel >= 4 ? 'level-indicator__point__level-4__active' : ''
                            }`}
                        >
                            <div className="level-indicator__sub-point" />
                        </div>
                        <div
                            className={`level-indicator__point ${
                                activatedLevel >= 5 ? 'level-indicator__point__level-5__active' : ''
                            }`}
                        >
                            <div className="level-indicator__sub-point" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

LevelIndicator.propTypes = {
    level: PropTypes.number.isRequired
};
