const COOK = {
    name: 'Cook',
    description: `Un site simple rassemblant différentes recettes de cuisine.<br>
    Mon but étant de ne plus me demander "Sur quel site se trouve la recette de tel ou tel plat ?"<br>
    J'ai donc créé mon propre site de cuisine avec une petite interface d'administration me permettant d'ajouter de nouvelles recettes quand bon me semble.`,
    technologies: ['HTML', 'CSS', 'VueJS', 'VueX', 'Firebase', 'ElementUI'],
    url: 'https://cook.morgandenis.fr',
    firstReleaseDate: '01/05/2019'
};

export default COOK;
