const LEVELS_EXPLANATION = [
    {
        full: "Noob : J'ai fait un hello world. J'en suis encore aux tutos de départ.",
        short: 'Noob'
    },
    {
        full: "Junior : J'ai des bases. Je sais me servir de l'outil mais uniquement pour des cas simples.",
        short: 'Junior'
    },
    {
        full: 'Intermédiaire : Je peux résoudre des problématiques concrètes.',
        short: 'Intermédiaire'
    },
    {
        full: 'Avancé : Je suis en mesure de résoudre la majorité des problèmes simplement.',
        short: 'Avancé'
    },
    {
        full: "Master : La technologie n'a plus vraiment de secrets pour moi.",
        short: 'Master'
    }
];

export default LEVELS_EXPLANATION;
