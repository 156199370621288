import dut from './study/dut';
import master from './study/ensc';

const STUDIES = [master, dut];
const resumeTitle = { name: 'studies', text: 'Ma formation', custom: 'title' };
const STUDIES_RESUME = [resumeTitle].concat(
    STUDIES.map((s, i) => ({ name: `study-${i}`, text: s.title }))
);

export { STUDIES, STUDIES_RESUME };
