const HOME = {
    name: 'Home',
    description: `Site de gestion de mon appartement.<br>
    Afin de gérer simplement les finances communes de l'appartement, j'ai décidé de réaliser un petit site s'occupant des calculs pour moi.<br>
    J'ai également profité de ce site pour afficher les données de mon capteur DIY de température / pression / humidité.<br/>
    Grâce à l'intégration d'une API exposant les données de la RATP j'affiche les prochains départs pour les stations qui m'intéressent le plus.`,
    technologies: [
        'HTML',
        'CSS',
        'React',
        'Redux',
        'Firebase',
        'Firebase Functions',
        'Material',
        'Arduino',
        'ESP8266',
        'DIY',
        'Open Data'
    ],
    url: 'https://home.morgandenis.fr',
    firstReleaseDate: '26/05/2019'
};

export default HOME;
