import React from 'react';
import PropTypes from 'prop-types';

export default function InfoLineTitle(props) {
    const { text, isBig } = props;
    if (isBig) {
        return <h2 className="mb-xs color-primary">{text}</h2>;
    }
    return <h3>{text}</h3>;
}

InfoLineTitle.propTypes = {
    isBig: PropTypes.bool,
    text: PropTypes.string.isRequired
};
