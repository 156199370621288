import React from 'react';
import { getDate } from '../../services';
import { EXPERIENCES } from '../../texts/experiences';

export default function CvWorkExperiences() {
    return (
        <React.Fragment>
            <div className="row space-between mb-s mobile-reverse">
                <div className="col full">
                    <h2 className="cv__part mb-s">Expériences</h2>
                    {EXPERIENCES.map((exp, index) => (
                        <div key={index} className="row mb-s space-between">
                            <div className="col">
                                <h3 className="row">
                                    {exp.title} @ {exp.company}
                                </h3>
                                <div className="row location">{exp.location}</div>
                                <div className="row">{exp.resume}</div>
                            </div>
                            <div className="col cv__date-container">
                                {getDate(exp.startDate)} - {getDate(exp.endDate)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
