import { useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

function MobileMenuLink({ path, name, onNavigate }) {
    const match = useMatch({ path });
    const nav = useNavigate();

    function navigate() {
        nav(path);
        onNavigate();
    }
    return (
        <div onClick={navigate}>
            <div className="flex">
                {match ? (
                    <div className="header-mobile__menu-item header-mobile__menu-item-active clickable p-xs pl-s full">
                        {name}
                    </div>
                ) : (
                    <div className="header-mobile__menu-item clickable p-xs pl-s full">{name}</div>
                )}
            </div>
        </div>
    );
}

MobileMenuLink.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onNavigate: PropTypes.func.isRequired
};

export default MobileMenuLink;
